import { useCallback, useState } from "react";

//
// We need to be able to hide the panel in two ways:
//
// 1. From the top level accordion component. (isPanelHidden)
//    There are some scenarios where we don't want to completely skip rendering a panel,
//    but just hide it with css.
//
// 2. From inside itself. (isSelfHidden)
//    Sometimes we only know if we need to render a panel from within the content component
//    eg a connected refinement list might want to be hidden if it has 0 refinement items
//

const useHiddenPanelState = (isPanelHidden = false) => {
  const [isSelfHidden, setIsSelfHidden] = useState(false);

  const hideSelf = useCallback(() => setIsSelfHidden(true), []);

  const showSelf = useCallback(() => setIsSelfHidden(false), []);

  const hidden = isPanelHidden || isSelfHidden;

  return { hideSelf, showSelf, hidden };
};

export default useHiddenPanelState;
