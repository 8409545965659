/*

A simple accordion component.

The title / toggler will be styled, it is up to you to style your panel contents

// usage:

import Accordion from "./Accordion";

const panels = [
  { title: "A Title", contents: <div>Some component</div>}
  { title: "Another Title", contents: <div>Some component 2</div>}
]

<Accordion panels={panels} initialPanel={0} allowToggle />

*/

import clsx from "clsx";
import { memo } from "react";

import Box from "@material-ui/core/Box";

import Panel from "./Panel";
import { useExpandPanelOnHashChange, useExpandedPanelsState } from "./hooks";
import { useStyles } from "./styles";

const Accordion = ({
  panels,
  panelContentProps,
  initialExpandedPanels = [],
  shouldOpenSinglePanel = false,
  hiddenPanels = [],
  footer = null,
}) => {
  const classes = useStyles();

  const { makeTogglePanel, isExpanded, openPanel } = useExpandedPanelsState(
    panels,
    initialExpandedPanels,
    shouldOpenSinglePanel
  );

  useExpandPanelOnHashChange(panels, openPanel);

  return (
    <>
      {panels.map(({ contents, ...rest }, idx) => (
        <Panel
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          idx={idx}
          isExpanded={isExpanded}
          makeTogglePanel={makeTogglePanel}
          hidePanel={hiddenPanels.includes(idx)}
          {...rest}
        >
          <Box {...panelContentProps}>{contents}</Box>
        </Panel>
      ))}

      {!!footer && (
        <Box className={clsx(classes.panelRoot, classes.footer)}>{footer}</Box>
      )}
    </>
  );
};

export default memo(Accordion);
