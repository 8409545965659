import { dateToEpoch } from "../utils/date";
import pluralize from "../utils/pluralize";

const midnightToday = new Date(new Date().setHours(0, 0, 0, 0));

export const filtersToStringWithMinStartDate = (filters) => {
  let filterString = filtersToString(filters);
  if (filterString !== "") {
    filterString += " AND ";
  }
  filterString += `startDate > ${dateToEpoch(midnightToday)}`;
  return filterString;
};

export const filtersToString = (filters) =>
  Object.entries(filters)
    .map(([attribute, value]) => `${attribute}:"${value}"`)
    .join(" AND ");

export const renderNodeRange = (min, max) => {
  if (!min) {
    return (
      <>
        {"< "}
        {max}
      </>
    );
  }
  if (!max) {
    return (
      <>
        {"> "}
        {min}
      </>
    );
  }
  if (min === max) {
    return min;
  }

  return (
    <>
      {min} to {max}
    </>
  );
};

export const renderStringRange = (min, max, unpluralizedPostfix = "") => {
  if (!min) {
    const postfix = unpluralizedPostfix
      ? `${unpluralizedPostfix}${pluralize(min)}`
      : "";

    return (
      <>
        {"Up To "}
        {max}
        {postfix}
      </>
    );
  }

  if (!max) {
    const postfix = unpluralizedPostfix
      ? `${unpluralizedPostfix}${pluralize(max)}`
      : "";

    return (
      <>
        {"From "}
        {min}
        {postfix}
      </>
    );
  }

  if (min === max) {
    const postfix = unpluralizedPostfix
      ? `${unpluralizedPostfix}${pluralize(min)}`
      : "";

    return `${min}${postfix}`;
  }

  const postfix = unpluralizedPostfix
    ? `${unpluralizedPostfix}${pluralize(max)}`
    : "";

  return <>{`${min} to ${max}${postfix}`}</>;
};

export const transformNoQuarantineReqLabel = (label) =>
  label.indexOf("notvaxed") > 0 ? "I am NOT vaccinated" : "I am vaccinated";

export const defaultValueToURLParam = (x) => (x === undefined ? "*" : x);
export const defaultURLParamToValue = (x) => (x === "*" ? undefined : x);

export function searchStateToSearchParams(searchState, searchConfig) {
  let searchParams = {};
  const { page, refinementList = {}, menu = {}, range = {} } = searchState;
  searchParams = {
    ...refinementList,
    ...menu,
  };

  Object.entries(range).forEach(([attribute, { min, max }]) => {
    if (min || max) {
      const filterDefinition = getFilterDefinition(attribute, searchConfig);
      const minAsURLParam = filterDefinition.valueToURLParam
        ? filterDefinition.valueToURLParam(min)
        : defaultValueToURLParam(min);
      const maxAsURLParam = filterDefinition.valueToURLParam
        ? filterDefinition.valueToURLParam(max)
        : defaultValueToURLParam(max);
      searchParams[attribute] = `${minAsURLParam}--${maxAsURLParam}`;
    }
  });

  if (page !== 1) {
    searchParams.page = page;
  }
  return searchParams;
}

export function searchParamsToURLSearchParams(searchParams) {
  const urlSearchParams = new URLSearchParams();
  Object.entries(searchParams).forEach(([attribute, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => urlSearchParams.append(attribute, v));
    } else {
      if (value) {
        urlSearchParams.append(attribute, value);
      }
    }
  });
  return urlSearchParams;
}

export function searchStateToURLSearchParams(searchState, searchConfig) {
  return searchParamsToURLSearchParams(
    searchStateToSearchParams(searchState, searchConfig)
  );
}

export const getFilterDefinition = (attribute, searchConfig) => {
  for (let section of searchConfig.sections) {
    const filterDefinition = section.filters.find(
      (filter) => filter.attribute === attribute
    );
    if (filterDefinition) return filterDefinition;
  }
};

const coerceToArray = (value) => (Array.isArray(value) ? value : [value]);

export function URLSearchParamsToSearchState(uRLSearchParams, searchConfig) {
  const searchParams = [...uRLSearchParams.entries()].reduce(
    (acc, [key, val]) => {
      if (acc.hasOwnProperty(key)) {
        if (Array.isArray(acc[key])) {
          acc[key] = [...acc[key], val];
        } else {
          acc[key] = [acc[key], val];
        }
      } else {
        acc[key] = val;
      }

      return acc;
    },
    {}
  );

  return searchParamsToSearchState(searchParams, searchConfig);
}

export function searchParamsToSearchState(searchParams, searchConfig) {
  const searchState = { menu: {}, range: {}, refinementList: {}, page: 1 };

  const { page, ...params } = searchParams;
  if (page) searchState.page = parseInt(page);

  Object.entries(params).forEach(([attribute, value]) => {
    const filterDefinition = getFilterDefinition(attribute, searchConfig);
    if (!filterDefinition) return;
    if (filterDefinition.type === "menu") {
      if (value) searchState[filterDefinition.type][attribute] = value;
    }
    if (filterDefinition.type === "refinementList") {
      if (value)
        searchState[filterDefinition.type][attribute] = coerceToArray(value);
    }
    if (filterDefinition.type === "range") {
      const [min, max] = value.split("--");

      searchState[filterDefinition.type][attribute] = {
        min: filterDefinition.urlParamToValue
          ? filterDefinition.urlParamToValue(min)
          : defaultURLParamToValue(min),
        max: filterDefinition.urlParamToValue
          ? filterDefinition.urlParamToValue(max)
          : defaultURLParamToValue(max),
      };
    }
  });
  return searchState;
}
