/* some date utility functions to help us work between JS Date objects and
   unix epoch timestamps (e.g. seconds since 1970)
   and getting an ISO date string from a JS date
*/

export const epochToDate = (v) => {
  const d = new Date(v * 1000);
  return isValidDate(d) ? d : undefined;
};

export const dateToEpoch = (v) =>
  isValidDate(v) ? v.valueOf() / 1000 : undefined;

export const epochToUTCISODateString = (v) =>
  epochToDate(v).toISOString()
.split("T")[0];

export const isoDateStringToUTCDate = (v) => {
  const d = new Date(`${v}T00:00:00Z`);
  return isValidDate(d) ? d : undefined;
};

export const isoDateStringToUTCSeconds = (v) => {
  const d = isoDateStringToUTCDate(v);
  return d !== undefined ? d.valueOf() / 1000 : undefined;
};

export const dateToISODateString = (v) =>
  `${v.getFullYear()}-${(v.getMonth() + 1).toString().padStart(2, "0")}-${v
    .getDate()
    .toString()
    .padStart(2, "0")}`;

export const isValidDate = (d) => d instanceof Date && !isNaN(d);
