import { useCallback, useState } from "react";

import { trackExpand } from "../tracking";

const useExpandedPanelsState = (
  panels,
  initialPanels,
  shouldOpenSinglePanel = false
) => {
  const [expandedPanels, setExpandedPanels] = useState(initialPanels);

  const isExpanded = useCallback(
    (idx) => expandedPanels.includes(idx),
    [expandedPanels]
  );

  const closePanel = useCallback((idx) => {
    setExpandedPanels((expandedPanels) => [
      ...expandedPanels.filter((expandedPanel) => expandedPanel !== idx),
    ]);
  }, []);

  const openSinglePanel = useCallback(
    (idx) => {
      setExpandedPanels([idx]);
      trackExpand(panels[idx]);
    },
    [panels]
  );

  const openAnotherPanel = useCallback(
    (idx) => {
      setExpandedPanels((expandedPanels) => [idx, ...expandedPanels]);
      trackExpand(panels[idx]);
    },
    [panels]
  );

  const openPanel = useCallback(
    (idx) => {
      if (shouldOpenSinglePanel) openSinglePanel(idx);
      else openAnotherPanel(idx);
    },
    [openSinglePanel, shouldOpenSinglePanel, openAnotherPanel]
  );

  const makeTogglePanel = useCallback(
    (idx) => () => {
      if (isExpanded(idx)) {
        closePanel(idx);
      } else {
        openPanel(idx);
      }
    },
    [closePanel, openPanel, isExpanded]
  );

  return { makeTogglePanel, isExpanded, openPanel };
};

export default useExpandedPanelsState;
