import {
  EVENT_NAME_PANEL_EXPANDED,
  trackAnalyticsEvent,
} from "src/utils/track";

export const trackExpand = (panel) => {
  const panelTitle =
    typeof panel.title === "string" ? panel.title : panel.titleStr || "";

  trackAnalyticsEvent(EVENT_NAME_PANEL_EXPANDED, {
    canonical_name: panel.canonicalName,
    title: panelTitle,
  });
};
