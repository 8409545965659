import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  panelRoot: (props) => ({
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    "&:first-child": {
      borderTop: 0,
    },

    scrollMarginTop: "56px",

    [theme.breakpoints.up("sm")]: {
      scrollMarginTop: "64px",
    },

    ...props.root,
  }),
  panelToggle: (props) => ({
    display: "block",
    width: "100%",
    border: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 0,

    outline: 0,
    cursor: "pointer",
    background: "white",
    ...props.panelToggle,
  }),
  panelToggleTitle: {
    display: "flex",
    width: "100%",
    textAlign: "left",
    justifyContent: "space-between",
    alignItems: "center",
  },
  panelToggleChevron: {
    marginLeft: theme.spacing(),
    transition: "transform 200ms",
    transform: "rotate(90deg)",
  },
  panelToggleChevronIn: {
    transform: "rotate(-90deg)",
  },
  icon: {
    marginRight: theme.spacing(),
  },
  hidden: {
    display: "none",
  },
  footer: {
    padding: theme.spacing(2),
  },
}));
