import { useCallback, useEffect } from "react";

const useExpandPanelOnHashChange = (panels, openPanel) => {
  const handleHashChange = useCallback(() => {
    const panelIndex = panels.findIndex(
      (panel) => `#${panel.id}` === location.hash
    );

    if (panelIndex > -1) {
      openPanel(panelIndex);
      history.replaceState(null, null, " ")
    }
  }, [panels, openPanel]);

  useEffect(() => {
    handleHashChange();

    window.addEventListener("hashchange", handleHashChange, false);

    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
    };
  }, [handleHashChange]);

  return handleHashChange;
};

export default useExpandPanelOnHashChange;