import clsx from "clsx";
import PropTypes from "prop-types";
import { createContext, memo, useMemo } from "react";

import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import { ChevronRight } from "@muchbetteradventures/components/dist/Icons";
import * as Icons from "@muchbetteradventures/components/dist/Icons";

import { useHiddenPanelState } from "./hooks";
import { useStyles } from "./styles";

export const AccordionPanelContext = createContext();
AccordionPanelContext.displayName = "AccordionPanelContext";

const Panel = ({
  idx = 0,
  id,
  title,
  titleVariant,
  icon,
  panelToggleContent = null,
  isExpanded,
  makeTogglePanel,
  children,
  styleOverrides,
  mountOnEnter = false,
  hidePanel = false,
}) => {
  const classes = useStyles(styleOverrides);
  const Icon = icon ? Icons[icon] : null;

  const expanded = useMemo(() => {
    return isExpanded && isExpanded(idx);
  }, [isExpanded, idx]);

  const toggleSelfExpanded = useMemo(
    () => makeTogglePanel && makeTogglePanel(idx),
    [makeTogglePanel, idx]
  );

  const { hidden, hideSelf, showSelf } = useHiddenPanelState(hidePanel);

  return (
    <AccordionPanelContext.Provider
      value={{
        toggleSelfExpanded,
        expanded,
        hideSelf,
        showSelf,
        hidden,
      }}
    >
      <div className={clsx(classes.panelRoot, { [classes.hidden]: hidden })} id={id}>
        <div style={{ overflow: "hidden" }}>
          <ButtonBase
            type="button"
            onClick={toggleSelfExpanded}
            className={classes.panelToggle}
            disableRipple
          >
            <Box className={classes.panelToggleTitle}>
              <Box display="flex" alignItems="center" flexGrow={1}>
                {Icon && <Icon className={classes.icon} />}
                <Typography
                  component="div"
                  variant={titleVariant}
                  style={{ flexGrow: 1, display: "flex" }}
                >
                  {title}
                </Typography>
              </Box>
              <ChevronRight
                fontSize="inherit"
                className={clsx(classes.panelToggleChevron, {
                  [classes.panelToggleChevronIn]: expanded,
                })}
              />
            </Box>

            {panelToggleContent}
          </ButtonBase>

          <Collapse in={expanded} timeout="auto" mountOnEnter={mountOnEnter}>
            {children}
          </Collapse>
        </div>
      </div>
    </AccordionPanelContext.Provider>
  );
};

Panel.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  titleVariant: PropTypes.string,
  isExpanded: PropTypes.func.isRequired,
  makeTogglePanel: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  icon: PropTypes.string,
  styleOverrides: PropTypes.object,
};

Panel.defaultProps = {
  titleVariant: "h4",
  icon: null,
  styleOverrides: {},
};

export default memo(Panel);
